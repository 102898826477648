iframe {
  height: 100%;
  width: 100%;
}

.iframe-container {
  height: 100%;
}

.flex-grid {
  display: flex;
  height: 35vh;
}

.col {
  flex: 1;
}

.dashboard {
  display: none;
}

.active-dashboard {
  display: block;
}
